import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { CSVLink, CSVDownload } from 'react-csv';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  splitByChar,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import PdfGenerator from '../../components/PdfGenerator';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const MachineUtilizationSummaryReportPage = () => {
  const [itemData, setItemData] = useState();
  const [showDownload, setShowDownload] = useState(false);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const [pdfContent, setPdfContent] = useState([]);
  const state = useContext(GlobalStateData);
  useEffect(() => {
    authCheck(state.authData);
    getMachines();
  }, []);

  useEffect(() => {
    if (tableData.length) {
      setShowDownload(true);
    } else {
      setShowDownload(false);
    }
  }, [tableData]);

  const [machinData, setMachineData] = useState([]);
  const [defaultMachine, setDefaultMachine] = useState([{ label: 'All', value: '' }]);
  const [machineOptions, setMachineOptions] = useState([]);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState([]);

  const handleModalOpen = (id) => {
    document.getElementsByTagName('body')[0].style.position = 'relative';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    let match = [];
    match = tableData.find((o, i) => {
      if (o.id == id) {
        return true;
      }
    });
    setShowData(match);
    setIsModalOpen(true);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleModalClose = (event) => {
    setIsModalOpen(false);
    document.getElementsByTagName('body')[0].style.position = 'unset';
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  };

  const modalWrapperClick = (e) => {
    if (e.target.classList.contains('popup-parent')) {
      handleModalClose();
    }
  };

  const postApi = () => {
    const postData = {
      data: {
        machinData,
        from,
        to,
      },
    };
    let validate = requireCheck(postData);
    if (from && to) {
      var deliveryDateValid = dateGreaterOrEqual(to, from, 'to', 'Invalid To Date');
    } else {
      var deliveryDateValid = true;
    }

    if (!validate || !deliveryDateValid) {
      return false;
    }
    let machinDataVal = machinData.toString();
    showLoader();
    axios
      .get(baseUri() + 'reports/machine-summary?machineId=' + machinDataVal + '&from=' + from + '&to=' + to, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        const pdfArray = [];
        let prevMachine = '';
        let prevTotalCount = '';
        let prevIdleCount = '';
        let prevMachineCode = '';
        response.data.data.forEach((t: { Machine_Code: any; Machine_Name: any; Productive: any; Duration: any }) => {
          let currMcCode = '';
          let currDescription = '';
          let currTotalHours = '';
          let currRunningHours = '';
          let currRunningPercent = '';
          let currIdleHours = '';
          let currIdlePercent = '';
          let idleBreakupsTitle = '';
          let idleBreakupHour = '';
          let idleBreakupPercent = '';
          if (t.Productive.toLowerCase() === 'idle' || t.Productive.toLowerCase() === 'productive') return;
          if (prevMachineCode !== t.Machine_Code) {
            prevMachine = t.Machine_Name;
            prevMachineCode = t.Machine_Code;
            prevTotalCount = response.data.data
              .filter((data) => {
                return (
                  +data.Machine_Code === +t.Machine_Code &&
                  (data.Productive.toLowerCase() === 'idle' || data.Productive.toLowerCase() === 'productive')
                );
              })
              .reduce((prevTotal, curr) => {
                const duration = moment.duration(curr.Duration);
                return prevTotal + duration.asMinutes();
              }, 0);
            prevIdleCount = moment
              .duration(
                response.data.data.filter(
                  (data) => +data.Machine_Code === +t.Machine_Code && data.Productive.toLowerCase() === 'idle',
                )[0].Duration,
              )
              .asMinutes();
            currMcCode = t.Machine_Code;
            currDescription = t.Machine_Name;
            currTotalHours = (+prevTotalCount / 60).toFixed(2);
            currRunningHours = moment
              .duration(
                response.data.data.filter(
                  (data) => +data.Machine_Code === +t.Machine_Code && data.Productive.toLowerCase() === 'productive',
                )[0]?.Duration || 0,
              )
              .asHours()
              .toFixed(2);
            currRunningPercent = ((+currRunningHours / currTotalHours) * 100).toFixed(2);
            currIdleHours = (+prevIdleCount / 60).toFixed(2);
            currIdlePercent = ((currIdleHours / currTotalHours) * 100).toFixed(2);
          }
          idleBreakupsTitle = t.Productive;
          idleBreakupHour = moment.duration(t.Duration).asHours().toFixed(2);
          idleBreakupPercent = ((+idleBreakupHour / (prevIdleCount / 60)) * 100).toFixed();
          pdfArray.push([
            currMcCode,
            currDescription,
            currTotalHours,
            currRunningHours,
            currRunningPercent,
            currIdleHours,
            currIdlePercent,
            idleBreakupsTitle,
            idleBreakupHour,
            idleBreakupPercent,
          ]);
        });
        setPdfContent(pdfArray);
        setItemData(response.data.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const pdfTitles = [
    'M/c Code',
    'Description',
    'Total Hours',
    'Running Hrs',
    'Running %',
    'Idle Hrs',
    'Idle Hr %',
    'Idle Brkups',
    'Hr',
    '%',
  ];

  const popup = isModalOpen ? (
    <div className="popup-parent" onClick={(e) => modalWrapperClick(e)}>
      <div className="popup-wrapper">
        <div
          className="pop-up-close"
          onClick={() => {
            handleModalClose();
          }}
        >
          x
        </div>
        <div className="pop-content">
          <h5>Machine Utilization Details</h5>
          <table className="pop-up-table">
            <tr>
              <td>Start Date </td>
              <td>{dateFormat(showData.start_time)}</td>
            </tr>
            <tr>
              <td>End Date </td>
              <td>{dateFormat(showData.end_time)}</td>
            </tr>
            <tr>
              <td>Customer Name </td>
              <td>{showData.customer_name}</td>
            </tr>
            <tr>
              <td>Sort Name </td>
              <td>{showData.sort_name}</td>
            </tr>
            <tr>
              <td>Group Name </td>
              <td>{showData.group_name}</td>
            </tr>

            <tr>
              <td>Lot No. </td>
              <td>{showData.lot_number}</td>
            </tr>
            <tr>
              <td>Machine Code </td>
              <td>{showData.machine_code}</td>
            </tr>
            <tr>
              <td>Machine </td>
              <td>{showData.machine_name}</td>
            </tr>
            <tr>
              <td>Machine Hours </td>
              <td>{showData.machine_hours}</td>
            </tr>
            <tr>
              <td>Material </td>
              <td>{showData.material}</td>
            </tr>

            <tr>
              <td>Operation Name</td>
              <td>{showData.operation_name}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  ) : null;

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'from':
        setFrom(event.target.value);
        if (event.target.value) {
          document.getElementsByClassName('to')[0].classList.add('require');
        } else {
          document.getElementsByClassName('to')[0].classList.remove('require');
        }
        break;
      case 'to':
        setTo(event.target.value);
        break;
    }
  };

  const machineChange = (event) => {
    let selected2 = [];
    if (event.length == 0) {
      event = [{ label: 'All', value: '' }];
    }
    if (event.length > 1 && event[0].label == 'All') {
      event.splice(0, 1);
    }
    if (event.length > 1 && event[event.length - 1].label == 'All') {
      event.splice(0, event.length - 1);
    }
    event.forEach(function (item, index) {
      selected2.push(item.value);
    });
    setMachineData(selected2);
    setDefaultMachine(event);
    pullDownReset('machine');
  };

  const clearFormData = () => {
    setFrom('');
    setTo('');
    setMachineData([]);
    setDefaultMachine([{ label: 'All', value: '' }]);
    resetRequired();
    document.getElementsByClassName('to')[0].classList.remove('require');
  };

  const getMachines = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          `machines?populate[0]=groupOps&populate[1]=groupOps.group&populate[2]=groupOps.operations&sort[0]=id:desc`,
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        let options = [];
        let groupInfo = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.machineCode + ' - ' + item.attributes.description, value: item.id });
          groupInfo.push({ id: item.id, groupOps: item.attributes.groupOps });
        });
        setMachineOptions(options);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const columns = [
    {
      name: 'Machine Code',
      selector: (row) => `${row.Machine_Code}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Machine Name',
      selector: (row) => `${row.Machine_Name}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Productive',
      selector: (row) => `${row.Productive}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Duration',
      selector: (row) => `${row.Duration}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];
  const filterColumns = ['Machine_Code', 'Machine_Name', 'Productive', 'Duration'];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      {popup}
      <SEO title="Machine Utilization Summary" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Machine Utilization Summary - Prod/Idle</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>Machine</label>
                  <SelectField
                    fullWidth
                    onChange={(e) => machineChange(e)}
                    options={machineOptions}
                    id="machine"
                    name="machine"
                    placeholder="Select Machine"
                    value={defaultMachine}
                    isMulti
                    multiple
                    className="pull-down multi-select"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>From Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="datetime-local"
                      name="from"
                      placeholder="From Date"
                      onChange={(e) => handleChange(e)}
                      value={from}
                      className="from"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>To Date</label>
                  <Input fullWidth size="Small">
                    <input
                      type="datetime-local"
                      name="to"
                      placeholder="To Date"
                      onChange={(e) => handleChange(e)}
                      value={to}
                      className="to"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Button status="Success" type="button" className="btnrad" shape="" halfWidth onClick={postApi}>
                    Get Report
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Machine Utilization Report</header>
            <CardBody>
              {showDownload && (
                <p>
                  <PdfGenerator
                    documentTitle="machine-utilization-summary"
                    data={{
                      from: from,
                      to: to,
                      tableTitles: pdfTitles,
                      tableContents: pdfContent,
                    }}
                  />
                  <CSVLink
                    style={{
                      marginLeft: '5px',
                    }}
                    data={tableData}
                  >
                    Download Excel
                  </CSVLink>
                </p>
              )}
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MachineUtilizationSummaryReportPage;
